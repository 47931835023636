@tailwind base;
@tailwind components;
@tailwind utilities;


.dropdown {
    position: absolute;
    background: white;
    top: 45px;
    right: 0;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    min-width: 60%;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
}

.dropdown_link {
    margin: 0.5rem;
    color: black;
}

/* .react-icons {
    vertical-align: middle;

} */

.district-swiper-slide {
    width: 150px !important;
    height: 320px !important;

}

.property-swiper-slide {
	width: 250px !important;
	margin-right: 10px;
}
	

.district-swiper-large-image {
    height: 300px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
}

.district-swiper-small-image {
    /* padding-bottom: 5px !important; */
    height: 150px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */

}



/* START: search bts styles */
.search-bts {
    @apply flex-1 max-sm:w-full flex justify-start items-center;
}

.search-bts__input {
    @apply w-full h-[48px] pl-12 p-4 rounded-l-full max-sm:rounded-full bg-light outline-none cursor-pointer text-sm;
}

.search-bts__options {
    @apply absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-light py-1 text-base shadow-lg ring-1 ring-maincolor ring-opacity-5 focus:outline-none sm:text-sm;
}

.search-bts__option {
    @apply cursor-default select-none py-2 pl-10 pr-4;
}

/* END: search bts styles */

/* START: Custom Filter styles */
.custom-filter__btn {
  @apply relative w-full min-w-[127px] flex justify-between items-center cursor-default rounded-lg bg-white py-2 px-3 text-left shadow-md sm:text-sm border;
}

.custom-filter__options {
  @apply absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm;
}
/* END: Custom Filter styles */

/*.swiper-button-next,
.swiper-button-prev {
  color: maincolor; 
}*/
.swiper-button-prev, .swiper-button-next {
    color: #ff0000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
    display: none;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* Custom CSS for scrollbar (Firefox) */
.scrollable {
  scrollbar-width: thin; /* Make the scrollbar thin */
  scrollbar-color: #6b7280 #4b5563; /* Set scrollbar track and thumb colors */
}

.scrollable::-webkit-scrollbar {
  width: 0; /* Disable WebKit scrollbar for Firefox */
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: transparent; /* Disable WebKit scrollbar thumb for Firefox */
}

.scrollable::-webkit-scrollbar {
    width: 8px; /* Adjust the width as needed */
  }
  
.scrollable::-webkit-scrollbar-thumb {
    @apply bg-maincolor; /* Set your desired scrollbar color */
    border-radius: 4px;
  }
  
.scrollable::-webkit-scrollbar-thumb:hover {
    @apply bg-secondary; /* Change the color on hover if desired */
}

.filepond {
  display: grid;
  gap: 2px;
  max-width: 960px;
    /* height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; */
}

.filepond--item {

  /* width: 30% !important; */
  max-width: 320px !important;
  height: 250px !important;
}

.loader {
  /* width: 300px;
  height: 100px;
  font-size: 30px; */
  text-align: center;
  line-height: 100px;
  color: rgba(255, 255, 255, 0.9);
  /* border-radius: 50px; */
  background: linear-gradient(180deg, #FFA63D, #FF3D77, #338AFF, #3CF0C5);
  background-size: 200%;
  animation: pulse 5s linear infinite;
}



@keyframes pulse {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.efg {
  background-image: linear-gradient(rgba(255,255,255,0.06), rgba(0,0,0,0.2));
  /* color: hsla(331, 45%, 95%, 0.78); */
	background-color: rgb(42, 201, 222);
	animation: efg 4s infinite alternate;
  /* box-shadow: 2px 2px 5px 2px hsla(235, 55%, 5%, .2),
  3px 2px 3px 1px hsla(235, 55%, 5%, .4); */
}

@keyframes efg {
	100% {background-color: rgb(17, 82, 91);}
}





